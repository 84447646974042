const FORCE_REFRESH_FLAG = "forceRefresh";

// ChunkLoadError 감지하여 자동 새로고침
export const handleChunkLoadError = () => {
  window.addEventListener("error", e => {
    if (e instanceof ErrorEvent && e.message.includes("ChunkLoadError")) {
      if (!localStorage.getItem(FORCE_REFRESH_FLAG)) {
        console.warn("🐶 ChunkLoadError detected. Reloading page...");
        localStorage.setItem(FORCE_REFRESH_FLAG, "true"); // 새로고침 방지 플래그 설정
        setTimeout(() => window.location.reload(), 100); // 100ms 후 새로고침 (비동기 처리)
      } else {
        console.warn(
          "🚀 ChunkLoadError detected again after reload. Showing error page."
        );
        showErrorPage(); // 새로고침 이후에도 에러 발생 → 에러 페이지 표시
      }
    }
  });

  // 사용자가 강제 새로고침하면 다시 새로고침 가능하게 초기화
  window.addEventListener("load", () => {
    localStorage.removeItem(FORCE_REFRESH_FLAG);
    console.warn("🐱 Page fully loaded. forceRefresh flag reset.");
  });
};

// 💥 에러 페이지 표시 함수
function showErrorPage() {
  document.body.innerHTML = `
    <div style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #f8f8f8;
      color: #333;
      font-family: Arial, sans-serif;
      text-align: center;
      gap: 12px;
    ">
      <h1>⚠️ 오류 발생</h1>
      <div>
      <p>현재 페이지를 불러오는 데 문제가 발생했습니다.</p>
      <p>인터넷 연결을 확인하거나, 잠시 후 다시 시도해주세요.</p>
      </div>
      <button onclick="location.reload()" 
        style="
          padding: 10px 20px;
          font-size: 16px;
          cursor: pointer;
          border: none;
          background-color: #6155de;
          color: white;
          border-radius: 5px;
          margin-top: 4px;
        ">
        다시 시도하기
      </button>
    </div>
  `;
}
