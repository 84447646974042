import { Cookies } from "react-cookie";
import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { showAlert } from "shared/lib";
import Swal from "sweetalert2";
import { instance } from "./axios";

const cookies = new Cookies();

class CallApi {
  async request(
    url: string,
    method: string,
    params?: any,
    data?: any,
    signal?: any
  ) {
    try {
      const res = await instance({
        url,
        method,
        params,
        data,
        signal,
      });
      return res?.data?.data;
    } catch (err: any) {
      const errorMessage = err.response?.data?.message;
      const errorCode = err.response?.data?.data?.errorCode;
      const path = window.location.pathname;

      if (errorCode === "JWT_EXPIRED") {
        showAlert(
          "세션이 만료되었습니다. \n 새로고침을 해주시기 바랍니다."
        ).then(({ isConfirmed }) => {
          if (isConfirmed) window.location.reload();
        });
      }
      // 회원탈퇴
      else if (path === "/myshop/info/withdrawal") {
        if (errorCode === "ERR_002_002_002") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        } else if (errorCode === "ERR_011_005_001") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 비회원 주문조회
      else if (path === "/non-member-orders") {
        if (errorCode === "ERR_002_004_002") {
          // ("해당 주문을 조회할 수 있는 권한이 없습니다. 다시 인증해주세요.");
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then(function (result) {
            if (result.isConfirmed) {
              cookies.remove("accessToken");
              cookies.remove("refreshToken");
              cookies.remove(COOKIE_KEYS.TEMP_CART_ID);
              localStorage.clear();
              window.location.href = "/signin";
            }
          });
        }
      }
      // 휴면해제
      else if (path === "/signin/dormant") {
        if (errorCode === "ERR_002_009_001") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then(function (result) {
            if (result.isConfirmed) {
              window.location.href = "/";
            }
          });
        } else if (
          errorCode === "ERR_COMMON_VALIDATION" ||
          errorCode === "ERR_000_004_001" ||
          errorCode === "ERR_000_004_002"
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then(function (result) {
            if (result.isConfirmed) {
              window.location.href = "/signin/dormant";
            }
          });
        }
      }
      // 비밀번호 변경 페이지 (비밀번호 변경 90일 지난 회원)
      else if (path === "/change-pw-recommend") {
        if (
          errorCode === "ERR_002_002_002" ||
          errorCode === "ERR_002_012_001"
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 내 정보
      else if (path === "/myshop/info") {
        if (
          errorCode === "ERR_002_002_002" ||
          errorCode === "ERR_002_002_001"
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 내 정보 관리
      else if (path === "/myshop/info/user-modify") {
        if (
          errorCode === "ERR_002_002_002" ||
          errorCode === "ERR_011_004_001" ||
          errorCode === "ERR_002_005_012" ||
          (errorCode === "ERR_COMMON_VALIDATION" &&
            errorMessage === "QR 등록에 실패하였습니다.") ||
          (errorCode === "ERR_COMMON_VALIDATION" &&
            errorMessage === "비밀번호가 틀렸습니다.")
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 찜
      else if (path === "/wishes/products") {
        if (errorMessage === "상품 번호를 입력해주세요.") {
          Swal.fire({
            text: "삭제할 상품을 선택해주세요.",
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 최근 본 상품
      else if (path === "/recent-products") {
        if (errorMessage === "상품 ID를 입력해주세요.") {
          Swal.fire({
            text: "삭제할 상품을 선택해주세요.",
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }

      // 아이디 찾기
      else if (path === "/find/id") {
        if (errorCode === "NOT_AUTHORIZED") {
          Swal.fire({
            text: "이름/휴대전화에 해당되는 ID가 없습니다.",
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        } else if (errorCode === "ERR_002_007_001") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 비밀번호 찾기
      else if (path === "/find/pw") {
        if (
          errorCode === "ERR_002_009_002" ||
          errorCode === "USERID_NOT_EXIST" ||
          errorCode === "ERR_002_014_002" ||
          errorCode === "ERR_002_014_003" ||
          errorCode === "ERR_002_015_003" ||
          errorCode === "ERR_002_003_006"
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 비밀번호 변경
      else if (path === "/find/pw/change") {
        if (
          errorCode === "ERR_COMMON_VALIDATION" ||
          errorCode === "ERR_002_010_001"
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 장바구니
      else if (path === "/cart") {
        if (errorCode === "ERR_COMMON_VALIDATION") {
          // 헤더의 카드아이디 값이 잘못되었습니다.
          Swal.fire({
            text: "오류가 발생했습니다. 다시 로그인해주세요.",
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then(function (result) {
            if (result.isConfirmed) {
              cookies.remove("accessToken");
              cookies.remove("refreshToken");
              localStorage.clear();
              localStorage.removeItem("userId");
              window.location.href = "/";
            }
          });
        } else if (errorCode === "ERR_000_007_001") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 상품상세
      else if (path.includes("/products/detail")) {
        if (errorCode === "ERR_COMMON_VALIDATION") {
          // 헤더의 카드아이디 값이 잘못되었습니다.
          if (errorMessage === "헤더의 카드 아이디 값이 잘못되었습니다.") {
            Swal.fire({
              text: "오류가 발생했습니다. 다시 로그인해주세요.",
              confirmButtonText: "확인",
              allowOutsideClick: false,
            }).then(function (result) {
              if (result.isConfirmed) {
                cookies.remove("accessToken");
                cookies.remove("refreshToken");
                localStorage.clear();
                localStorage.removeItem("userId");
                window.location.href = "/";
              }
            });
          }
        } else if (errorCode === "ERR_013_001_006") {
          Swal.fire({
            text: "장바구니에 담을 수 있는 재고수량을 초과하였습니다.",
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        } else if (
          errorCode === "ERR_000_007_001" ||
          errorCode === "ERR_013_001_001"
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 중고채시세표
      else if (path.includes("used-price")) {
        if (errorCode === "ERR_COMMON_VALIDATION") {
          window.location.href = "/used-price";
        }
      }
      // 고객센터 > 게시판 문의
      else if (path?.includes("/help/qna")) {
        if (errorCode === "ERR_009_004_002") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then(function (result) {
            if (result.isConfirmed) {
              window.location.href = "/help/qna";
            }
          });
        } else if (
          errorCode === "ERR_004_011_001" ||
          errorCode === "ERR_000_001_004" || // 파일 업로딩 실패||
          errorCode === "ERR_COMMON_VALIDATION"
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 결제하기
      else if (path === "/checkout") {
        if (errorCode === "ERR_COMMON_VALIDATION") {
          if (errorMessage === "구매자 휴대 전화 번호가 형식이 다릅니다.") {
            Swal.fire({
              text: "구매자 휴대 전화 번호가 형식이 다릅니다.",
              confirmButtonText: "확인",
              allowOutsideClick: false,
            });
          } else {
            Swal.fire({
              text: errorMessage,
              confirmButtonText: "확인",
              allowOutsideClick: false,
            });
          }
        } else if (errorCode === "ERR_013_001_002") {
          // 판매 종료된 상품입니다.
          Swal.fire({
            text: "판매 종료된 상품이 있습니다.",
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 환불계좌변경
      else if (path === "/myshop/orders/refund-account-change") {
        if (errorCode === "ERR_015_019_003") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 고객센터
      else if (path?.includes("/help")) {
        if (errorCode === "ERR_000_001_004") {
          // 파일 업로딩 에러
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        } else if (errorCode === "ERR_009_008_001") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then(function (result) {
            if (result.isConfirmed) {
              window.history.back();
            }
          });
        }
      }
      // 판매주문/배송관리
      else if (path === "/myshop/sales/orders") {
        if (errorCode === "ERR_006_006_004") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 판매 주문 상세
      else if (path === "/myshop/sales/orders/order-detail") {
        if (errorCode === "ERR_006_001_001") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then(function (result) {
            if (result.isConfirmed) {
              window.history.back();
            }
          });
        }
      }
      // 판매취소
      else if (path === "/myshop/sales/orders/cancel-sale") {
        if (errorCode === "ERR_006_004_004") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 판매문의 관리
      else if (path === "/myshop/sales/qna") {
        if (
          errorCode === "ERR_007_002_007" ||
          errorCode === "ERR_007_002_004"
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 발송하기(교환 재발송하기)
      else if (path === "/myshop/sales/orders/sending") {
        if (
          errorCode === "ERR_006_008_014" ||
          errorCode === "ERR_006_008_008" ||
          errorCode === "ERR_006_008_011" ||
          errorCode === "ERR_006_002_014" ||
          errorCode === "ERR_006_002_004"
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 상품 등록
      else if (path === "/products/register") {
        if (
          errorCode === "ERR_004_001_005" ||
          errorCode === "ERR_004_001_006" ||
          errorCode === "ERR_004_001_007" ||
          errorCode === "ERR_004_001_008" ||
          errorCode === "ERR_004_001_009" ||
          errorCode === "ERR_004_001_010" ||
          errorCode === "ERR_004_001_011" ||
          errorCode === "ERR_004_001_012" ||
          errorCode === "ERR_004_001_013" ||
          errorCode === "ERR_004_001_014" ||
          errorCode === "ERR_004_001_015" ||
          errorCode === "ERR_004_001_016" ||
          errorCode === "ERR_004_001_017" ||
          errorCode === "ERR_004_001_018" ||
          errorCode === "ERR_004_001_019" ||
          errorCode === "ERR_004_001_020" ||
          errorCode === "ERR_004_001_021" ||
          errorCode === "ERR_004_001_022" ||
          errorCode === "ERR_004_001_023" ||
          errorCode === "ERR_004_001_024" ||
          errorCode === "ERR_004_001_025" ||
          errorCode === "ERR_004_001_026" ||
          errorCode === "ERR_004_001_027" ||
          errorCode === "ERR_004_001_028" ||
          errorCode === "ERR_004_001_029" ||
          errorCode === "ERR_004_001_030" ||
          errorCode === "ERR_004_001_031" ||
          errorCode === "ERR_004_001_032" ||
          errorCode === "ERR_004_001_033" ||
          errorCode === "ERR_004_001_034" ||
          errorCode === "ERR_004_001_035" ||
          errorCode === "ERR_004_001_036" ||
          errorCode === "ERR_004_001_037" ||
          errorCode === "ERR_004_001_038" ||
          errorCode === "ERR_004_001_039" ||
          errorCode === "ERR_004_001_040" ||
          errorCode === "ERR_004_001_041" ||
          errorCode === "ERR_004_001_042" ||
          errorCode === "ERR_004_001_043" ||
          errorCode === "ERR_004_001_044" ||
          errorCode === "ERR_004_001_045" ||
          errorCode === "ERR_004_001_046" ||
          errorCode === "ERR_004_001_047" ||
          errorCode === "ERR_004_001_048" ||
          errorCode === "ERR_004_001_049" ||
          errorCode === "ERR_004_001_050" ||
          errorCode === "ERR_004_001_051" ||
          errorCode === "ERR_004_001_052" ||
          errorCode === "ERR_004_001_053" ||
          errorCode === "ERR_004_001_054" ||
          errorCode === "ERR_004_001_055" ||
          errorCode === "ERR_004_001_056" ||
          errorCode === "ERR_004_001_057" ||
          errorCode === "ERR_004_001_058" ||
          errorCode === "ERR_004_001_059" ||
          errorCode === "ERR_004_001_060" ||
          errorCode === "ERR_004_001_061" ||
          errorCode === "ERR_004_001_062" ||
          errorCode === "ERR_004_001_063" ||
          errorCode === "ERR_004_001_064" ||
          errorCode === "ERR_004_001_065" ||
          errorCode === "ERR_004_001_066" ||
          errorCode === "ERR_004_001_067" ||
          errorCode === "ERR_004_001_068" ||
          errorCode === "ERR_004_001_069" ||
          errorCode === "ERR_004_001_070" ||
          errorCode === "ERR_004_001_071" ||
          errorCode === "ERR_004_001_072" ||
          errorCode === "ERR_004_001_073" ||
          errorCode === "ERR_004_001_074" ||
          errorCode === "ERR_004_001_075" ||
          errorCode === "ERR_004_001_076" ||
          errorCode === "ERR_004_001_077" ||
          errorCode === "ERR_004_001_078" ||
          errorCode === "ERR_004_001_079" ||
          errorCode === "ERR_004_001_080" ||
          errorCode === "ERR_004_001_081" ||
          errorCode === "ERR_004_001_082" ||
          errorCode === "ERR_004_001_083" ||
          errorCode === "ERR_004_001_084" ||
          errorCode === "ERR_004_001_085" ||
          errorCode === "ERR_004_001_086" ||
          errorCode === "ERR_004_001_087" ||
          errorCode === "ERR_004_001_088" ||
          errorCode === "ERR_004_001_089" ||
          errorCode === "ERR_004_001_090" ||
          errorCode === "ERR_004_001_091" ||
          errorCode === "ERR_004_001_092" ||
          errorCode === "ERR_004_001_093" ||
          errorCode === "ERR_004_001_094" ||
          errorCode === "ERR_004_001_095" ||
          errorCode === "ERR_004_001_096" ||
          errorCode === "ERR_004_001_097" ||
          errorCode === "ERR_004_001_098" ||
          errorCode === "ERR_004_001_099" ||
          errorCode === "ERR_004_001_100" ||
          errorCode === "ERR_004_001_101" ||
          errorCode === "ERR_004_001_102" ||
          errorCode === "ERR_004_001_103" ||
          errorCode === "ERR_004_001_104" ||
          errorCode === "ERR_004_001_105" ||
          errorCode === "ERR_004_001_106" ||
          errorCode === "ERR_004_001_107" ||
          errorCode === "ERR_004_001_108" ||
          errorCode === "ERR_004_001_109" ||
          errorCode === "ERR_004_001_110" ||
          errorCode === "ERR_004_001_111" ||
          errorCode === "ERR_004_001_112" ||
          errorCode === "ERR_004_001_113" ||
          errorCode === "ERR_004_001_114" ||
          errorCode === "ERR_004_001_115" ||
          errorCode === "ERR_004_001_116" ||
          errorCode === "ERR_004_001_117" ||
          errorCode === "ERR_004_001_118" ||
          errorCode === "ERR_004_001_119" ||
          errorCode === "ERR_004_001_120" ||
          errorCode === "ERR_004_001_121" ||
          errorCode === "ERR_004_001_122" ||
          errorCode === "ERR_004_001_123" ||
          errorCode === "ERR_004_001_124" ||
          errorCode === "ERR_004_001_125" ||
          errorCode === "ERR_004_001_126" ||
          errorCode === "ERR_004_001_127" ||
          errorCode === "ERR_004_001_128" ||
          errorCode === "ERR_004_001_129" ||
          errorCode === "ERR_004_001_130" ||
          errorCode === "ERR_004_001_131" ||
          errorCode === "ERR_004_001_132" ||
          errorCode === "ERR_004_001_133" ||
          errorCode === "ERR_004_001_134" ||
          errorCode === "ERR_004_001_135" ||
          errorCode === "ERR_004_001_136" ||
          errorCode === "ERR_004_001_137" ||
          errorCode === "ERR_004_001_138" ||
          errorCode === "ERR_004_001_139" ||
          errorCode === "ERR_004_001_140" ||
          errorCode === "ERR_004_001_159" ||
          errorCode === "ERR_004_001_158" ||
          errorCode === "ERR_004_005_019" ||
          errorCode === "ERR_004_001_162" ||
          (errorCode === "ERR_COMMON_VALIDATION" &&
            errorMessage ===
              "제목이나 상세설명에 지원하지 않는 문자열이 존재 합니다.") ||
          (errorCode === "ERR_COMMON_VALIDATION" &&
            errorMessage === "서비스 준비중 입니다.")
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 상품 복사 등록
      else if (path?.includes("/products/register/")) {
        // 유효하지 않은 productId
        if (
          errorCode === "ERR_004_001_007" ||
          errorCode === "ERR_004_002_003"
        ) {
          Swal.fire({
            text: "비정상적인 접근입니다.",
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then(function (result) {
            window.location.href = "/";
          });
        } else if (errorCode === "ERR_004_001_137") {
          Swal.fire({
            text: "존재하지 않는 이미지 파일이 있습니다.",
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        } else if (
          errorCode === "ERR_004_001_005" ||
          errorCode === "ERR_004_001_006" ||
          // errorCode === "ERR_004_001_007" ||
          errorCode === "ERR_004_001_008" ||
          errorCode === "ERR_004_001_009" ||
          errorCode === "ERR_004_001_010" ||
          errorCode === "ERR_004_001_011" ||
          errorCode === "ERR_004_001_012" ||
          errorCode === "ERR_004_001_013" ||
          errorCode === "ERR_004_001_014" ||
          errorCode === "ERR_004_001_015" ||
          errorCode === "ERR_004_001_016" ||
          errorCode === "ERR_004_001_017" ||
          errorCode === "ERR_004_001_018" ||
          errorCode === "ERR_004_001_019" ||
          errorCode === "ERR_004_001_020" ||
          errorCode === "ERR_004_001_021" ||
          errorCode === "ERR_004_001_022" ||
          errorCode === "ERR_004_001_023" ||
          errorCode === "ERR_004_001_024" ||
          errorCode === "ERR_004_001_025" ||
          errorCode === "ERR_004_001_026" ||
          errorCode === "ERR_004_001_027" ||
          errorCode === "ERR_004_001_028" ||
          errorCode === "ERR_004_001_029" ||
          errorCode === "ERR_004_001_030" ||
          errorCode === "ERR_004_001_031" ||
          errorCode === "ERR_004_001_032" ||
          errorCode === "ERR_004_001_033" ||
          errorCode === "ERR_004_001_034" ||
          errorCode === "ERR_004_001_035" ||
          errorCode === "ERR_004_001_036" ||
          errorCode === "ERR_004_001_037" ||
          errorCode === "ERR_004_001_038" ||
          errorCode === "ERR_004_001_039" ||
          errorCode === "ERR_004_001_040" ||
          errorCode === "ERR_004_001_041" ||
          errorCode === "ERR_004_001_042" ||
          errorCode === "ERR_004_001_043" ||
          errorCode === "ERR_004_001_044" ||
          errorCode === "ERR_004_001_045" ||
          errorCode === "ERR_004_001_046" ||
          errorCode === "ERR_004_001_047" ||
          errorCode === "ERR_004_001_048" ||
          errorCode === "ERR_004_001_049" ||
          errorCode === "ERR_004_001_050" ||
          errorCode === "ERR_004_001_051" ||
          errorCode === "ERR_004_001_052" ||
          errorCode === "ERR_004_001_053" ||
          errorCode === "ERR_004_001_054" ||
          errorCode === "ERR_004_001_055" ||
          errorCode === "ERR_004_001_056" ||
          errorCode === "ERR_004_001_057" ||
          errorCode === "ERR_004_001_058" ||
          errorCode === "ERR_004_001_059" ||
          errorCode === "ERR_004_001_060" ||
          errorCode === "ERR_004_001_061" ||
          errorCode === "ERR_004_001_062" ||
          errorCode === "ERR_004_001_063" ||
          errorCode === "ERR_004_001_064" ||
          errorCode === "ERR_004_001_065" ||
          errorCode === "ERR_004_001_066" ||
          errorCode === "ERR_004_001_067" ||
          errorCode === "ERR_004_001_068" ||
          errorCode === "ERR_004_001_069" ||
          errorCode === "ERR_004_001_070" ||
          errorCode === "ERR_004_001_071" ||
          errorCode === "ERR_004_001_072" ||
          errorCode === "ERR_004_001_073" ||
          errorCode === "ERR_004_001_074" ||
          errorCode === "ERR_004_001_075" ||
          errorCode === "ERR_004_001_076" ||
          errorCode === "ERR_004_001_077" ||
          errorCode === "ERR_004_001_078" ||
          errorCode === "ERR_004_001_079" ||
          errorCode === "ERR_004_001_080" ||
          errorCode === "ERR_004_001_081" ||
          errorCode === "ERR_004_001_082" ||
          errorCode === "ERR_004_001_083" ||
          errorCode === "ERR_004_001_084" ||
          errorCode === "ERR_004_001_085" ||
          errorCode === "ERR_004_001_086" ||
          errorCode === "ERR_004_001_087" ||
          errorCode === "ERR_004_001_088" ||
          errorCode === "ERR_004_001_089" ||
          errorCode === "ERR_004_001_090" ||
          errorCode === "ERR_004_001_091" ||
          errorCode === "ERR_004_001_092" ||
          errorCode === "ERR_004_001_093" ||
          errorCode === "ERR_004_001_094" ||
          errorCode === "ERR_004_001_095" ||
          errorCode === "ERR_004_001_096" ||
          errorCode === "ERR_004_001_097" ||
          errorCode === "ERR_004_001_098" ||
          errorCode === "ERR_004_001_099" ||
          errorCode === "ERR_004_001_100" ||
          errorCode === "ERR_004_001_101" ||
          errorCode === "ERR_004_001_102" ||
          errorCode === "ERR_004_001_103" ||
          errorCode === "ERR_004_001_104" ||
          errorCode === "ERR_004_001_105" ||
          errorCode === "ERR_004_001_106" ||
          errorCode === "ERR_004_001_107" ||
          errorCode === "ERR_004_001_108" ||
          errorCode === "ERR_004_001_109" ||
          errorCode === "ERR_004_001_110" ||
          errorCode === "ERR_004_001_111" ||
          errorCode === "ERR_004_001_112" ||
          errorCode === "ERR_004_001_113" ||
          errorCode === "ERR_004_001_114" ||
          errorCode === "ERR_004_001_115" ||
          errorCode === "ERR_004_001_116" ||
          errorCode === "ERR_004_001_117" ||
          errorCode === "ERR_004_001_118" ||
          errorCode === "ERR_004_001_119" ||
          errorCode === "ERR_004_001_120" ||
          errorCode === "ERR_004_001_121" ||
          errorCode === "ERR_004_001_122" ||
          errorCode === "ERR_004_001_123" ||
          errorCode === "ERR_004_001_124" ||
          errorCode === "ERR_004_001_125" ||
          errorCode === "ERR_004_001_126" ||
          errorCode === "ERR_004_001_127" ||
          errorCode === "ERR_004_001_128" ||
          errorCode === "ERR_004_001_129" ||
          errorCode === "ERR_004_001_130" ||
          errorCode === "ERR_004_001_131" ||
          errorCode === "ERR_004_001_132" ||
          errorCode === "ERR_004_001_133" ||
          errorCode === "ERR_004_001_134" ||
          errorCode === "ERR_004_001_135" ||
          errorCode === "ERR_004_001_136" ||
          // errorCode === "ERR_004_001_137" ||
          errorCode === "ERR_004_001_138" ||
          errorCode === "ERR_004_001_139" ||
          errorCode === "ERR_004_001_140" ||
          errorCode === "ERR_004_001_159" ||
          errorCode === "ERR_004_001_158" ||
          errorCode === "ERR_004_005_019" ||
          errorCode === "ERR_004_001_162" ||
          (errorCode === "ERR_COMMON_VALIDATION" &&
            errorMessage ===
              "제목이나 상세설명에 지원하지 않는 문자열이 존재 합니다.") ||
          (errorCode === "ERR_COMMON_VALIDATION" &&
            errorMessage === "서비스 준비중 입니다.")
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 상품 수정
      else if (path.includes("/products/edit")) {
        if (
          errorCode === "ERR_004_001_005" ||
          errorCode === "ERR_004_001_006" ||
          errorCode === "ERR_004_001_007" ||
          errorCode === "ERR_004_001_008" ||
          errorCode === "ERR_004_001_009" ||
          errorCode === "ERR_004_001_010" ||
          errorCode === "ERR_004_001_011" ||
          errorCode === "ERR_004_001_012" ||
          errorCode === "ERR_004_001_013" ||
          errorCode === "ERR_004_001_014" ||
          errorCode === "ERR_004_001_015" ||
          errorCode === "ERR_004_001_016" ||
          errorCode === "ERR_004_001_017" ||
          errorCode === "ERR_004_001_018" ||
          errorCode === "ERR_004_001_019" ||
          errorCode === "ERR_004_001_020" ||
          errorCode === "ERR_004_001_021" ||
          errorCode === "ERR_004_001_022" ||
          errorCode === "ERR_004_001_023" ||
          errorCode === "ERR_004_001_024" ||
          errorCode === "ERR_004_001_025" ||
          errorCode === "ERR_004_001_026" ||
          errorCode === "ERR_004_001_027" ||
          errorCode === "ERR_004_001_028" ||
          errorCode === "ERR_004_001_029" ||
          errorCode === "ERR_004_001_030" ||
          errorCode === "ERR_004_001_031" ||
          errorCode === "ERR_004_001_032" ||
          errorCode === "ERR_004_001_033" ||
          errorCode === "ERR_004_001_034" ||
          errorCode === "ERR_004_001_035" ||
          errorCode === "ERR_004_001_036" ||
          errorCode === "ERR_004_001_037" ||
          errorCode === "ERR_004_001_038" ||
          errorCode === "ERR_004_001_039" ||
          errorCode === "ERR_004_001_040" ||
          errorCode === "ERR_004_001_041" ||
          errorCode === "ERR_004_001_042" ||
          errorCode === "ERR_004_001_043" ||
          errorCode === "ERR_004_001_044" ||
          errorCode === "ERR_004_001_045" ||
          errorCode === "ERR_004_001_046" ||
          errorCode === "ERR_004_001_047" ||
          errorCode === "ERR_004_001_048" ||
          errorCode === "ERR_004_001_049" ||
          errorCode === "ERR_004_001_050" ||
          errorCode === "ERR_004_001_051" ||
          errorCode === "ERR_004_001_052" ||
          errorCode === "ERR_004_001_053" ||
          errorCode === "ERR_004_001_054" ||
          errorCode === "ERR_004_001_055" ||
          errorCode === "ERR_004_001_056" ||
          errorCode === "ERR_004_001_057" ||
          errorCode === "ERR_004_001_058" ||
          errorCode === "ERR_004_001_059" ||
          errorCode === "ERR_004_001_060" ||
          errorCode === "ERR_004_001_061" ||
          errorCode === "ERR_004_001_062" ||
          errorCode === "ERR_004_001_063" ||
          errorCode === "ERR_004_001_064" ||
          errorCode === "ERR_004_001_065" ||
          errorCode === "ERR_004_001_066" ||
          errorCode === "ERR_004_001_067" ||
          errorCode === "ERR_004_001_068" ||
          errorCode === "ERR_004_001_069" ||
          errorCode === "ERR_004_001_070" ||
          errorCode === "ERR_004_001_071" ||
          errorCode === "ERR_004_001_072" ||
          errorCode === "ERR_004_001_073" ||
          errorCode === "ERR_004_001_074" ||
          errorCode === "ERR_004_001_075" ||
          errorCode === "ERR_004_001_076" ||
          errorCode === "ERR_004_001_077" ||
          errorCode === "ERR_004_001_078" ||
          errorCode === "ERR_004_001_079" ||
          errorCode === "ERR_004_001_080" ||
          errorCode === "ERR_004_001_081" ||
          errorCode === "ERR_004_001_082" ||
          errorCode === "ERR_004_001_083" ||
          errorCode === "ERR_004_001_084" ||
          errorCode === "ERR_004_001_085" ||
          errorCode === "ERR_004_001_086" ||
          errorCode === "ERR_004_001_087" ||
          errorCode === "ERR_004_001_088" ||
          errorCode === "ERR_004_001_089" ||
          errorCode === "ERR_004_001_090" ||
          errorCode === "ERR_004_001_091" ||
          errorCode === "ERR_004_001_092" ||
          errorCode === "ERR_004_001_093" ||
          errorCode === "ERR_004_001_094" ||
          errorCode === "ERR_004_001_095" ||
          errorCode === "ERR_004_001_096" ||
          errorCode === "ERR_004_001_097" ||
          errorCode === "ERR_004_001_098" ||
          errorCode === "ERR_004_001_099" ||
          errorCode === "ERR_004_001_100" ||
          errorCode === "ERR_004_001_101" ||
          errorCode === "ERR_004_001_102" ||
          errorCode === "ERR_004_001_103" ||
          errorCode === "ERR_004_001_104" ||
          errorCode === "ERR_004_001_105" ||
          errorCode === "ERR_004_001_106" ||
          errorCode === "ERR_004_001_107" ||
          errorCode === "ERR_004_001_108" ||
          errorCode === "ERR_004_001_109" ||
          errorCode === "ERR_004_001_110" ||
          errorCode === "ERR_004_001_111" ||
          errorCode === "ERR_004_001_112" ||
          errorCode === "ERR_004_001_113" ||
          errorCode === "ERR_004_001_114" ||
          errorCode === "ERR_004_001_115" ||
          errorCode === "ERR_004_001_116" ||
          errorCode === "ERR_004_001_117" ||
          errorCode === "ERR_004_001_118" ||
          errorCode === "ERR_004_001_119" ||
          errorCode === "ERR_004_001_120" ||
          errorCode === "ERR_004_001_121" ||
          errorCode === "ERR_004_001_122" ||
          errorCode === "ERR_004_001_123" ||
          errorCode === "ERR_004_001_124" ||
          errorCode === "ERR_004_001_125" ||
          errorCode === "ERR_004_001_126" ||
          errorCode === "ERR_004_001_127" ||
          errorCode === "ERR_004_001_128" ||
          errorCode === "ERR_004_001_129" ||
          errorCode === "ERR_004_001_130" ||
          errorCode === "ERR_004_001_131" ||
          errorCode === "ERR_004_001_132" ||
          errorCode === "ERR_004_001_133" ||
          errorCode === "ERR_004_001_134" ||
          errorCode === "ERR_004_001_135" ||
          errorCode === "ERR_004_001_136" ||
          errorCode === "ERR_004_001_137" ||
          errorCode === "ERR_004_001_138" ||
          errorCode === "ERR_004_001_139" ||
          errorCode === "ERR_004_001_140" ||
          errorCode === "ERR_004_001_159" ||
          errorCode === "ERR_004_001_158" ||
          errorCode === "ERR_004_005_019" ||
          errorCode === "ERR_004_001_162" ||
          (errorCode === "ERR_COMMON_VALIDATION" &&
            errorMessage === "서비스 준비중 입니다.")
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        } else if (errorCode === "ERR_004_001_137") {
          Swal.fire({
            text: "존재하지 않는 이미지 파일이 있습니다.",
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        } else if (errorCode === "ERR_004_002_003") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then(function (result) {
            window.location.href = "/";
          });
        }
      }
      // 판매상품목록
      else if (path === "/myshop/sales/products") {
        if (
          errorCode === "ERR_004_003_013" ||
          errorCode === "ERR_017_004_005" ||
          errorCode === "ERR_017_004_002" ||
          errorCode === "ERR_017_004_004" ||
          errorCode === "ERR_004_003_015" ||
          errorCode === "ERR_004_003_016" ||
          errorCode === "PRODUCT_REGIST_RESTRICT" ||
          errorCode === "ERR_004_003_014" ||
          errorCode === "ERR_004_003_018" ||
          errorCode === "ERR_004_003_008" ||
          errorCode === "ERR_004_003_017" ||
          errorCode === "ERR_004_003_009"
        ) {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      }
      // 제휴/입점 신청 게시판
      else if (path?.includes("/apply-cooperation/")) {
        if (errorMessage === "글 보기권한이 없습니다.") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then(function (result) {
            window.location.href = "/apply-cooperation";
          });
        }
      } else if (path === "/myshop/sales/settlement/vat") {
        if (errorCode === "ERR_016_004_002") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          });
        }
      } else if (path?.includes("/store/")) {
        if (errorCode === "ERR_012_001_001") {
          Swal.fire({
            text: errorMessage,
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then(function (result) {
            window.history.back();
          });
        }
      } else if (
        (errorCode === "ERR_COMMON_VALIDATION" &&
          errorMessage !== "헤더의 카드 아이디 값이 잘못되었습니다.") ||
        errorCode === "ERR_000_001_005"
      ) {
        Swal.fire({
          text: errorMessage,
          confirmButtonText: "확인",
          allowOutsideClick: false,
        });
      } else if (
        errorCode === "WRONG_JWT_SIGN" ||
        errorCode === "ACCESS_TOKEN_USED"
      ) {
        Swal.fire({
          text: "오류가 발생했습니다. 다시 로그인해주세요.",
          confirmButtonText: "확인",
          allowOutsideClick: false,
        }).then(function (result) {
          if (result.isConfirmed) {
            cookies.remove("accessToken");
            cookies.remove("refreshToken");
            cookies.remove(COOKIE_KEYS.TEMP_CART_ID);
            localStorage.clear();
            window.location.href = "/";
          }
        });
      }
    }
  }
}
export default new CallApi();
