import { useAppDispatch, useAppSelector } from "app/appStore";
import { SaleStatus, SaleType } from "entities/product/api/types";
import {
  AfterService,
  DeliveryType,
  OriginArea,
  SalePeriod,
  type ProductRegisterData,
} from "entities/product/seller-product";
import { useEffect, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useBoolean } from "shared/hooks/useBoolean/useBoolean";
import { resetSaveProductState, setCategory } from "./saveProductStore";

type Props = {
  productInfo: ProductRegisterData | undefined;
  isEditMode?: boolean;
};

export const useSellerProductForm = ({ productInfo, isEditMode }: Props) => {
  const productRegisterForm = useForm<ProductRegisterData>({
    mode: "onChange",
    defaultValues: {
      saleStatus: SaleStatus.판매중,
      afterService: AfterService.불가능,
      saleType: SaleType.중고상품,
      deliveryType: DeliveryType.무료,
      islandFee: 5000,
      returnFee: 5000,
      bundleDelivery: 1,
      deliveryOverseas: false,
      salePeriod: SalePeriod["6개월"],
      coopRegStatus: true,
    },
  });
  const { setValue } = productRegisterForm;
  const watch = useWatch({ control: productRegisterForm.control });
  const isInitialized = useRef(false);

  //  상품 옵션
  const hasProductOption = useBoolean(Boolean(watch.optionInfo), {
    onOff: () => {
      setValue("optionInfo", undefined);
    },
  });

  const dispatch = useAppDispatch();
  const { 소분류 } = useAppSelector(state => state.saveProduct.카테고리);

  useEffect(() => {
    return () => {
      dispatch(resetSaveProductState());
    };
  }, [dispatch]);

  //  초기화
  useEffect(() => {
    if (!productInfo) return;
    if (isInitialized.current) return;

    isInitialized.current = true;

    if (isEditMode) {
      setValue(
        "saleStatus",
        productInfo.saleStatus === SaleStatus.판매중 ||
          productInfo.saleStatus === SaleStatus.승인보류
          ? SaleStatus.판매중
          : SaleStatus.판매중지
      );
    }

    // categoryCode
    setValue("categoryCode", productInfo.categoryCode);
    const _categoryCode = productInfo.categoryCode?.split("r");
    const 대분류 = _categoryCode[0];
    const 중분류 =
      _categoryCode?.length >= 2
        ? `${_categoryCode[0]}r${_categoryCode[1]}`
        : undefined;
    const 소분류 =
      _categoryCode?.length >= 3 ? productInfo.categoryCode : undefined;

    dispatch(setCategory({ 대분류, 소분류, 중분류 }));

    // 브랜드/모델
    setValue("brandCode", productInfo.brandCode);
    setValue("modelCode", productInfo.modelCode);

    // 상품명
    setValue("productName", productInfo.productName);

    // 원산지
    setValue("originArea", productInfo.originArea);
    if (Number(productInfo.originArea) === OriginArea.수입산) {
      setValue("originAreaName", productInfo.originAreaName);
    }

    // 정품구분
    setValue("authenticType", productInfo.authenticType);

    // A/S
    setValue("afterService", productInfo.afterService);
    if (productInfo.afterService === AfterService.가능)
      setValue("afterServiceDetail", productInfo.afterServiceDetail);

    // ProductStatusSection ===
    setValue("saleType", productInfo.saleType ?? SaleType.중고상품);
    setValue("usedCondition", productInfo.usedCondition);
    setValue("usedPeriod", productInfo.usedPeriod);

    // 골프용품 > 골프공 > 로스트볼 선택 시 상품종류 "중고상품"만 가능
    if (소분류 === "2153r01r05") {
      setValue("saleType", SaleType.중고상품);
    }

    // 상품옵션
    setValue("stockQuantity", productInfo.stockQuantity);
    setValue("optionInfo", productInfo.optionInfo);

    // ProductPriceSection ===
    setValue("orgPrice", productInfo.orgPrice);

    // 배송정보
    setValue("deliveryFee", productInfo.deliveryFee);

    setValue("deliveryType", Number(productInfo.deliveryType));
    setValue("islandFee", productInfo.islandFee);
    setValue("returnFee", productInfo.returnFee);
    setValue("bundleDelivery", Number(productInfo.bundleDelivery));
    setValue("deliveryOverseas", productInfo.deliveryOverseas);
    setValue("deliveryDday", productInfo.deliveryDday);
    setValue("isTariff", productInfo.isTariff);

    // 상품정보제공고시입력 ===
    setValue("noticeInfo", productInfo.noticeInfo);

    // ProductDetailSection ===
    setValue("imagesInfo", productInfo.imagesInfo);
    setValue("detailContent", productInfo.detailContent);

    // 상품 자동종료일
    setValue("salePeriod", productInfo.salePeriod ?? SalePeriod["6개월"]);
    // 제휴사 연동
    setValue("coopRegStatus", productInfo.coopRegStatus);
    setValue("coopPolicyAgree", productInfo.coopPolicyAgree);
  }, [isEditMode, productInfo, setValue, 소분류, dispatch]);

  return {
    productRegisterForm,
    watch,
    hasProductOption,
  };
};
