import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "app/appQueryClient";
import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { handleChunkLoadError } from "shared/lib/handleChunkLoadError";
import App from "./app/App";
import store from "./app/appStore";

Sentry.init({
  dsn: "https://3dc1114ce2b0a9e932855c915f796f58@o4508894436524032.ingest.us.sentry.io/4508894438621184",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // "localhost",
    /^https:\/\/stg-fseller\.pricegolf\.co\.kr\//,
    /^https:\/\/m\.pricegolf\.co\.kr\//,
  ],

  environment: process.env.REACT_APP_ENV ?? "dev2",

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

console.log(`== env: ${process.env.REACT_APP_ENV ?? "dev2"}`);

const persistor = persistStore(store);

const renderApp = () => {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CookiesProvider>
            <React.StrictMode>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </React.StrictMode>
          </CookiesProvider>
        </PersistGate>
      </Provider>
      <ReactQueryDevtools />
    </QueryClientProvider>,
    document.getElementById("root")
  );
};

handleChunkLoadError();
renderApp();
