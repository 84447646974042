import {
  usePageHistoryUpdate,
  useRestrictedAccess,
  useSyncAuthState,
} from "app/App.hooks";
import { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { scrollToTop } from "shared/lib";
import { showTokenInfo } from "shared/lib/showTokenInfo";
import "shared/styles/common.scss";
import "shared/styles/reset.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/scss/navigation";
import "./App.scss";
import { RouteList } from "./routes/Routes";

export default function App() {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/maintenance");
  // }, [navigate]);

  /**
   * HTTP 프로토콜을 HTTPS로 리다이렉트하는 코드입니다.
   * 이정현님이 작성한 코드에 오류가 발생하여, 부분적으로 수정한 코드입니다.
   * 로컬 환경(localhost)에서는 리다이렉트되지 않도록 설정되었습니다.
   */
  useEffect(() => {
    const isLocalhost = [
      "localhost",
      "127.0.0.1",
      "172.30.1.74",
      "::1",
    ].includes(window.location.hostname);
    const isHttp = window.location.protocol === "http:";

    if (!isLocalhost && isHttp) {
      window.location.replace(window.location.href.replace("http:", "https:"));
    }
  }, []);

  usePageHistoryUpdate();
  useSyncAuthState();
  showTokenInfo();
  useRestrictedAccess({
    restrictedPaths: [
      "/products/register",
      "/myshop",
      "/products/edit",
      "/direct-purchase",
      "/apply-cooperation",
      "/help/illegal-deal",
      "/help/fake",
      "/help/report",
      "/talk",
      "/notify",
      "/change-pw-recommend",
    ],
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  return <RouteList />;
}
