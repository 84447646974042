import { useAppSelector } from "app/appStore";
import { useNavigate } from "react-router-dom";
import Shared from "shared/ui";
import type { IconName } from "shared/ui/Icon/Icon";
import "./Navigation.scss";

const BOTTOM_NAV_MENUS: { name: string; path: string; icon: IconName }[] = [
  {
    name: "카테고리",
    path: "/menu",
    icon: "menuCategory",
  },
  { name: "검색", path: "/search", icon: "menuSearch" },
  { name: "홈", path: "/", icon: "menuHome" },
  {
    name: "마이페이지",
    path: "/myshop",
    icon: "menuMyPage",
  },
  {
    name: "내 상품 팔기",
    path: "/products/register",
    icon: "menuSell",
  },
  // {
  //   name: "최근 본 상품",
  //   path: "/recent-products",
  //   icon: "menuHistory",
  // },
];

export const BottomNavigation = ({ noMargin }: { noMargin?: boolean }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector(state => state.user);

  function getMyPagePath(path: string) {
    if (isLoggedIn === false) return "/signin";
    return path;
  }

  return (
    <div style={{ paddingTop: noMargin ? 0 : 68 }}>
      <nav id="BottomNavigation">
        {BOTTOM_NAV_MENUS.map(menu => (
          <button
            type="button"
            key={menu.name}
            className="navigation_button"
            onClick={() => {
              if (menu?.name === "마이페이지") {
                navigate(getMyPagePath(menu.path));
              } else {
                navigate(menu.path);
              }
              document.querySelector("html")?.scrollTo(0, 0);
            }}
          >
            <Shared.Icon name={menu.icon} size={56} color="black" />
            <p>{menu.name}</p>
          </button>
        ))}
      </nav>
    </div>
  );
};
